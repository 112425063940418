import type { RedeemCode } from '../..'
import React, { useMemo } from 'react'

import { WuModal } from '../../../../modal'

import SelfModalBody from './self-modal'
import GiftModalBody from './gift-modal'

export type LineItemInfoModalBodyProps = {
  t: any
  item: RedeemCode | null
  clickMap?: { onCopy?: (evt: React.MouseEvent<HTMLButtonElement>) => void } & {
    onConfirm?: (evt: React.MouseEvent<HTMLButtonElement>) => void
  } & { onClose?: (evt: React.MouseEvent<HTMLButtonElement>) => void }
}

export type LineItemInfoModalProps = {
  type: 'gift' | 'self' | ''
} & LineItemInfoModalBodyProps

const getModalBodyComponent = (
  type: LineItemInfoModalProps['type'],
): React.FC<LineItemInfoModalBodyProps> | null => {
  const Component = {
    self: SelfModalBody,
    gift: GiftModalBody,
  } as Record<string, React.FC<LineItemInfoModalBodyProps>>

  return Component[type] || null
}

const LineItemInfoModal = ({ type, item, clickMap, t }: LineItemInfoModalProps) => {
  const ModalBody = useMemo(() => {
    const Component = getModalBodyComponent(type)

    if (!Component || !item) return null

    return <Component item={item} clickMap={clickMap} t={t} />
  }, [type, item, clickMap])

  return (
    <WuModal
      open={!!ModalBody}
      onClose={clickMap?.onClose}
      maxWidth='296px'
      padding='24px'
      hideCloseButton
    >
      {ModalBody as React.ReactElement}
    </WuModal>
  )
}

export default LineItemInfoModal
