import type { CollectionOfferType } from './types'

import React, { useMemo } from 'react'

export type CollectionOfferInfoProps = {
  currentProductId?: string
  collectionOffer: CollectionOfferType
  renderImage: (attributes: {
    key: string
    src: string
    alt: string
    width: number
    height: number
    style: React.CSSProperties
  }) => React.ReactNode
  pageLink: React.ReactNode
  disableLink?: boolean
}

export const CollectionOfferInfo = ({
  renderImage,
  collectionOffer,
  currentProductId,
  pageLink,
  disableLink,
}: CollectionOfferInfoProps) => {
  const ProductComponents = useMemo(() => {
    const getProductHrefById = (productId: string) => `/product/${productId}`
    const products = collectionOffer.products.filter(product => product.id !== currentProductId)
    return (
      <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
        {products.map((product, productIdx) => {
          return (
            <li key={product.id} style={{ position: 'relative', marginBottom: 16 }}>
              <a
                href={getProductHrefById(product.id ?? '')}
                style={{ display: 'flex', textDecoration: 'none' }}
                onClick={e => {
                  if (disableLink) {
                    e.preventDefault()
                  }
                }}
              >
                <div style={{ marginRight: 12 }}>
                  {renderImage({
                    width: 192,
                    height: 108,
                    style: { borderRadius: 8 },
                    alt: product.name,
                    src: product.shopCoverImageUrl,
                    key: `${product.productVariantId}_${productIdx}`,
                  })}
                </div>

                <div
                  style={{
                    flexGrow: 1,
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '24.5px',
                    color: '#383B3D', // grayscale-800
                  }}
                >
                  {product.name}
                </div>
              </a>
            </li>
          )
        })}
      </ul>
    )
  }, [collectionOffer, renderImage, currentProductId])

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {ProductComponents}

      {pageLink}
    </div>
  )
}
