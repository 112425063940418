import React from 'react'

import { Icon } from '../icon'

export type CollectionOfferSavedDescProps = {
  productCountText: string
  savedPriceText: string
  addToCartText: string
  totalPriceText?: string
  handleAddToCart?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const CollectionOfferSavedDesc = ({
  productCountText,
  savedPriceText,
  totalPriceText = '',
  addToCartText,
  handleAddToCart,
}: CollectionOfferSavedDescProps) => {
  const isCollectionOfferPage = totalPriceText && savedPriceText

  return (
    <div
      style={{
        backgroundColor: '#FEEBDA', // peach-100
        padding: '8px 12px',
        textAlign: 'center',
        borderRadius: 8,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: isCollectionOfferPage ? 'column' : 'row',
          gap: isCollectionOfferPage ? 4.5 : 4,
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '24.5px',
          color: '#F06449', // peach-500
          marginBottom: handleAddToCart ? 12 : 0,
        }}
      >
        {isCollectionOfferPage ? (
          <>
            <div
              style={{
                fontSize: 12,
                lineHeight: '21px',
                letterSpacing: '0.06px',
                color: '#383B3D', // grayscale-800
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: productCountText,
                }}
              />
              <span>，</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: totalPriceText,
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon type='volunteer_activism' size={16} />
              <span dangerouslySetInnerHTML={{ __html: savedPriceText }} />
            </div>
          </>
        ) : savedPriceText ? (
          <>
            <Icon type='volunteer_activism' size={16} />
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              dangerouslySetInnerHTML={{ __html: savedPriceText }}
            />
          </>
        ) : null}
      </div>
      {handleAddToCart && (
        <button
          type='button'
          onClick={handleAddToCart}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 4,
            backgroundColor: '#F06449', // peach-500
            padding: '5.5px 18px',
            borderRadius: 18,
            width: '100%',
            margin: '0 auto',
            appearance: 'none',
            border: 0,
            outline: 0,
            cursor: 'pointer',
            color: '#ffffff',
            fontSize: 14,
            lineHeight: '24.5px',
            letterSpacing: '0.035px',
          }}
        >
          <Icon type='shopping_cart' />
          <span>{addToCartText}</span>
        </button>
      )}
    </div>
  )
}
