import styled from '@emotion/styled'
import * as React from 'react'
import { useForm } from 'react-hook-form'

import Button from '../atoms/button'
import ErrorMessage from '../atoms/error-message'
import FormControl from '../atoms/form-control'
import Input from '../atoms/input'
import { SignInFormData, SignUpFormData } from './index'
import { useLocale } from './local-provider'
import { AuthType } from './types'

const defaultValues = {
  email: '',
  password: '',
}

interface PropTypes {
  handleAuth: (data: SignInFormData | SignUpFormData) => void
  setLayout: (mode: AuthType) => void
}

const SignInForm = ({ handleAuth, setLayout }: PropTypes) => {
  const { signin, error, loading, password, email, forgetPassword } = useLocale()
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues,
  })

  const onSubmit = async (data: SignInFormData) => {
    await handleAuth(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={Boolean(errors.email)}>
        <Input
          type='email'
          placeholder={email.plhr}
          {...register('email', {
            required: error.required,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: error.email || '',
            },
          })}
        />
      </FormControl>
      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}

      <FormControl isInvalid={Boolean(errors.password)}>
        <Input
          type='password'
          placeholder={password.plhr}
          {...register('password', {
            required: error.required,
            minLength: { value: 6, message: error.password || '' },
          })}
        />
      </FormControl>
      {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}

      <ForgetPasswordLink onClick={() => setLayout('forget-password')} type='button'>
        {forgetPassword.title}
      </ForgetPasswordLink>

      <Button type='submit' disabled={isSubmitting} style={{ marginTop: '1rem' }}>
        {isSubmitting ? loading : signin.submit}
      </Button>
    </form>
  )
}

export default SignInForm

const ForgetPasswordLink = styled.button`
  float: right;
  font-size: 0.8rem;
  color: #7c8282;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
`
