import * as React from 'react'

interface AuthFormLocale {
  signin: {
    title: string
    submit: string
    hintTxt: string
    hintBtn: string
  }
  signup: {
    title: string
    submit: string
    hintTxt: string
    hintBtn: string
    useEmail: string
  }
  privacy: {
    message: string
    linkText: string
  }
  error: {
    required: string
    email: string // format invalid
    password: string // length too short
  }
  loading: string
  name: {
    label: string
    plhr: string
  }
  email: {
    label: string
    plhr: string
  }
  password: {
    label: string
    plhr: string
  }
  forgetPassword: {
    title: string
    pageDesc: string
    sendEmail: string
    toSignIn: string
    successMsg: string
  }
  resetPwd: {
    title: string
    newPwd: {
      title: string
      plhdr: string
    }
    confirmNewPwd: {
      title: string
      plhdr: string
    }
    savePwd: string
    loading: string
    err: {
      noPwd: string
      pwdNotMatch: string
      pwdTooShort: string
    }
  }
}

export interface Locales {
  en: AuthFormLocale
  'zh-TW': AuthFormLocale
}

const defaultLocales: Locales = {
  en: {
    signin: {
      title: 'Welcome back',
      submit: 'Login',
      hintTxt: 'New to WORD UP?',
      hintBtn: 'Sign Up',
    },
    signup: {
      title: 'Hello',
      submit: 'Register',
      hintTxt: 'Already have a WORD UP account?',
      hintBtn: 'Login',
      useEmail: 'Signup using email',
    },
    forgetPassword: {
      title: 'Forgot password?',
      pageDesc: "Don't worry! Tell me your email, we will help you!",
      sendEmail: 'Send an email to reset password',
      toSignIn: 'Sign in',
      successMsg: "You'll receive an email within few minutes to reset your password.",
    },
    privacy: {
      message: 'Creating an account means you’re okay with our',
      linkText: 'Privacy Policy & Terms of Service',
    },
    error: {
      required: 'Requried field',
      email: 'Invalid email',
      password: 'Minimum is 6 characters',
    },
    loading: 'Loading...',
    name: {
      label: 'Username',
      plhr: 'Enter username',
    },
    email: {
      label: 'Email',
      plhr: 'Enter email',
    },
    password: {
      label: 'Password',
      plhr: 'Enter password',
    },
    resetPwd: {
      title: 'Reset Password',
      newPwd: {
        title: 'New password',
        plhdr: 'Fill in your new password',
      },
      confirmNewPwd: {
        title: 'Password confirmation',
        plhdr: 'Fill in your new password again',
      },
      savePwd: 'Save password',
      loading: 'Loading',
      err: {
        noPwd: 'Fill in your password',
        pwdNotMatch: 'Password confirmation does not match',
        pwdTooShort: 'Password needs 6 characters at least',
      },
    },
  },
  'zh-TW': {
    signin: {
      title: '登入',
      submit: '登入',
      hintTxt: '還沒有帳號？',
      hintBtn: '註冊',
    },
    signup: {
      title: '註冊新帳號',
      submit: '送出',
      hintTxt: '已經有帳號了？',
      hintBtn: '登入',
      useEmail: '或用 Email 註冊',
    },
    forgetPassword: {
      title: '忘記密碼？',
      pageDesc: '不用擔心！給我你的電子信箱帳號，馬上幫你解決問題！',
      sendEmail: '發送重設密碼郵件',
      toSignIn: '回到登入頁',
      successMsg:
        '我們將會在數分鐘內，發送一封 Email 到你輸入的信箱，點擊信箱內的連結我們會幫助你重新設定密碼。',
    },
    privacy: {
      message: '註冊即表示您已閱讀並同意',
      linkText: '隱私權及使用者條款',
    },
    error: {
      required: '必填欄位',
      email: '電子郵件格式錯誤',
      password: '至少為 6 位英數字',
    },
    loading: '處理中...',
    name: {
      label: '使用者名稱',
      plhr: '輸入使用者名稱',
    },
    email: {
      label: '電子郵件',
      plhr: '輸入電子信箱',
    },
    password: {
      label: '密碼',
      plhr: '輸入密碼',
    },
    resetPwd: {
      title: '重設新密碼',
      newPwd: {
        title: '你的新密碼',
        plhdr: '請輸入你的新密碼',
      },
      confirmNewPwd: {
        title: '確認新密碼',
        plhdr: '請再輸入一次新密碼',
      },
      savePwd: '儲存密碼',
      loading: '載入中',
      err: {
        noPwd: '請輸入密碼',
        pwdNotMatch: '密碼不符',
        pwdTooShort: '至少為 6 位數',
      },
    },
  },
}

export const LocalContext = React.createContext(defaultLocales['zh-TW'])

type PropTypes = React.PropsWithChildren<{
  defaultLang: 'zh-TW' | 'en'
  locales?: Locales
}>

export const LocalProvider: React.FC<PropTypes> = ({
  defaultLang,
  locales = defaultLocales,
  children,
}) => {
  const [lang, setLang] = React.useState(defaultLang)

  React.useEffect(() => {
    setLang(defaultLang)
  }, [defaultLang])

  return <LocalContext.Provider value={locales[lang]}>{children}</LocalContext.Provider>
}

export const useLocale = () => React.useContext(LocalContext)
