import type { CheckoutApiResLineItem } from '@/apis/shop/types'
import type { Event } from './types'
import { getAnonymousId } from './utils'
import getGtagBasicEventParams from '@/features/tracking/gtag/getGtagBasicEventParams'

const GTAG_ID = process.env.NEXT_PUBLIC_GTAG_ID as string

const getWuConversionTrackingId = (key: Event): string => {
  const mapping: { [K in Event]?: string } = {
    purchase: process.env.NEXT_PUBLIC_GTAG_CONVERSION_PURCHASE,
    add_to_cart: process.env.NEXT_PUBLIC_GTAG_CONVERSION_ADD_TO_CART,
    request_trial: process.env.NEXT_PUBLIC_GTAG_CONVERSION_REQUEST_TRIAL,
    receive_trial: process.env.NEXT_PUBLIC_GTAG_CONVERSION_RECEIVE_TRIAL,
    free_download: process.env.NEXT_PUBLIC_GTAG_CONVERSION_RECEIVE_FREE_DOWNLOAD,
  }
  if (!mapping[key]) {
    throw new Error(`No conversion target for ${key}`)
  }
  return mapping[key]!
}

const internal = {
  sendEvent: ({
    name,
    params = {},
    onSuccess,
    onFailure,
  }: {
    name: Event
    params?: object
    onSuccess?: () => void
    onFailure?: (error: unknown) => void
  }) => {
    try {
      window.gtag('event', name, { ...getGtagBasicEventParams(), ...params })

      if (typeof onSuccess === 'function') onSuccess()
    } catch (error) {
      if (typeof onFailure === 'function') onFailure(error)
    }
  },
}

const external = {
  pageView: (gtagID: string) => {
    window.gtag('event', 'page_view', { send_to: gtagID })
  },
  sendEvent: ({ name, params, gtagID }: { name: Event; params?: object; gtagID: string }) => {
    window.gtag('event', name, { ...params, send_to: gtagID })
  },
}

function setUser(userId: string | undefined) {
  window.gtag('config', GTAG_ID, { user_id: userId || getAnonymousId() })
}

function viewItem(
  info: {
    productId: string
    name: string
    publisherName: string
    examName: string
    salePrice: number
    currency: string
  },
  action: 'visit' | 'click' = 'visit',
) {
  const { productId, name, publisherName, examName, salePrice, currency } = info
  const actionType = action === 'click' ? 'select_item' : 'view_item'

  internal.sendEvent({
    name: actionType,
    params: {
      currency,
      items: [
        {
          google_business_vertical: 'custom',
          item_id: productId,
          item_name: name,
          item_brand: publisherName,
          item_category: examName,
          price: salePrice,
          quantity: 1,
        },
      ],
    },
  })
}

function adsConversionTracking({
  value = 0,
  transactionId = '',
  currency = 'TWD',
  monetary = true,
  items,
  contentId,
  trackingId,
  isThirdParty,
  isCollectionOffer,
}: {
  value?: number
  transactionId?: string
  currency?: string
  monetary?: boolean
  items?: CheckoutApiResLineItem[]
  contentId?: string
  isCollectionOffer?: boolean
  trackingId: string
  isThirdParty: boolean
}) {
  if (!window) return

  // according to Google's doc, absent currency value means this conversion is a KPI,
  // instead of a real monetary conversion
  // https://support.google.com/google-ads/answer/3419241
  const commonParams: {
    value?: number
    transaction_id?: string
    currency?: string | null
    items?: CheckoutApiResLineItem[]
    contentId?: string
    is_collection_offer?: boolean
  } = {
    value,
    currency: monetary ? currency : null,
    transaction_id: transactionId,
    items,
    contentId,
  }

  if (typeof isCollectionOffer === 'boolean') {
    commonParams['is_collection_offer'] = isCollectionOffer
  }

  if (isThirdParty) {
    external.sendEvent({ name: 'conversion', params: commonParams, gtagID: trackingId })
  } else {
    internal.sendEvent({ name: 'conversion', params: { ...commonParams, send_to: trackingId } })
  }
}

async function purchase({
  orderId,
  totalPrice,
  currency = 'TWD',
  lineItems,
  coupon,
  thirdPartyTrackingId,
}: {
  orderId: string
  totalPrice: number
  currency?: string
  coupon: string | undefined
  lineItems: CheckoutApiResLineItem[]
  thirdPartyTrackingId?: string
}) {
  const purchaseParams = {
    send_to: thirdPartyTrackingId ?? undefined,
    transaction_id: orderId,
    value: totalPrice,
    currency,
    shipping: 0,
    items: lineItems.map((item, index) => ({
      index,
      item_id: item.productId,
      item_variant: item.variantId,
      quantity: item.qty,
      item_name: item.name,
      price: item.subtotal,
    })),
    coupon,
  }

  if (thirdPartyTrackingId) {
    external.sendEvent({ name: 'purchase', params: purchaseParams, gtagID: thirdPartyTrackingId })
  } else {
    adsConversionTracking({
      trackingId: getWuConversionTrackingId('purchase'),
      value: totalPrice,
      transactionId: orderId,
      items: lineItems,
      isThirdParty: false,
    })
    internal.sendEvent({ name: 'purchase', params: purchaseParams })
  }
}

function beginCheckout() {
  internal.sendEvent({ name: 'begin_checkout' })
}

function receiveTrial({ productId }: { productId: string }) {
  adsConversionTracking({
    trackingId: getWuConversionTrackingId('receive_trial'),
    isThirdParty: false,
    monetary: false,
    // we use product id as transaction id for trial,
    // since for every product, user can only receive one trial, it can be used as a unique identifier
    transactionId: productId,
    contentId: productId,
  })
  internal.sendEvent({ name: 'receive_trial', params: { productId } })
}

function requestTrial({ productId }: { productId: string }) {
  adsConversionTracking({
    trackingId: getWuConversionTrackingId('request_trial'),
    isThirdParty: false,
    monetary: false,
    contentId: productId,
  })
  internal.sendEvent({ name: 'request_trial', params: { productId } })
}

function freeDownload({ materialId, orderId }: { materialId: string; orderId: string }) {
  adsConversionTracking({
    trackingId: getWuConversionTrackingId('free_download'),
    isThirdParty: false,
    monetary: false,
    transactionId: orderId,
    contentId: materialId,
  })
  internal.sendEvent({ name: 'free_download', params: { materialId } })
}

function expandShowMore({ productId }: { productId: string }) {
  internal.sendEvent({ name: 'shop_detail_page_main_info_show_more', params: { productId } })
}

export const GOOGLE_CONVERSION_API = {
  KEY: 'GOOGLE_CONVERSION',
  VALUES: ['gclid', 'gbraid', 'wbraid'],
} as const
export const sendGtagEvent = internal.sendEvent
export { adsConversionTracking }
export default {
  setUser,
  internal,
  external,
  ec: {
    viewItem,
    purchase,
    requestTrial,
    receiveTrial,
    freeDownload,
    beginCheckout,
    expandShowMore,
  },
}
