import React from 'react'
import { ProgressTextPropsType } from '../types'

import themeVar from '../../../theme/themeVar'

const COLORS = themeVar.color.palette

const ProgressRightText = ({
  style,
  currentBuyerCount,
  goalBuyerCount,
  t,
}: ProgressTextPropsType) => {
  const calculatePercentage = (
    currentBuyerCount: number | undefined,
    goalBuyerCount: number | undefined,
  ) => {
    if (currentBuyerCount && goalBuyerCount) {
      return Math.floor((currentBuyerCount / goalBuyerCount) * 100) || 0
    }
    return 0
  }

  const percentage = calculatePercentage(currentBuyerCount, goalBuyerCount)

  const commonStyle = {
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '33px',
    letterSpacing: '0.11px',
  }

  switch (style) {
    case 'crowdfunding': {
      return (
        <div
          style={{
            ...commonStyle,
            color: COLORS['grayscale-800'],
            opacity: Number.isFinite(percentage) ? 1 : 0,
          }}
        >
          {percentage}%
        </div>
      )
    }

    case 'limited_qty_offer': {
      return (
        <div
          style={{
            ...commonStyle,
            display: 'flex',
            alignItems: 'center',
            color:
              currentBuyerCount >= goalBuyerCount
                ? COLORS['grayscale-500']
                : COLORS['grayscale-800'],
          }}
        >
          {currentBuyerCount > goalBuyerCount ? goalBuyerCount : currentBuyerCount}/{goalBuyerCount}
          <span style={{ marginLeft: '0.25rem' }}>
            {t('crowdin:product-detail-page.info.quotas')}
          </span>
        </div>
      )
    }

    default:
      return null
  }
}

export default ProgressRightText
