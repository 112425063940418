import { isStaging } from '@/libs/env'

const TestEnvWarning = () => {
  if (!isStaging) return null

  return (
    <div
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        e.currentTarget.setAttribute('hidden', 'true')
      }}
      className='z-announcement bg-peach-500 text-grayscale-000 fixed left-0 right-0 top-[60px] cursor-pointer py-1 text-center'
    >
      這是 WORD UP 的測試環境，請將網址改成 https://shop.wordup.com.tw 才是正式網站
    </div>
  )
}

export default TestEnvWarning
