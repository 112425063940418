import { Stage } from '../constants'

interface LocalStorage {
  get: (key: string) => string | null
  set: (key: string, data: string) => void
  remove: (key: string) => void
  clearAll: (cb?: any) => void
}

const createInstance = (stage: Stage): LocalStorage => {
  const stagedKey = (key: string) => `${stage !== 'production' ? `${stage}-` : ''}${key}`

  return {
    get: key => {
      return localStorage.getItem(stagedKey(key))
    },
    set: (key, data) => {
      localStorage.setItem(stagedKey(key), data)
    },
    remove: key => {
      localStorage.removeItem(stagedKey(key))
    },
    clearAll: cb => {
      localStorage.clear()

      if (typeof cb === 'function') {
        setTimeout(() => {
          cb()
        }, 500)
      }
    },
  }
}

export default createInstance
