import React from 'react'
import RatingStars from '../rating-stars'

import { Text } from '../text'

function UserInfoBar({
  rating,
  userName,
  createdAt,
  imgUrl,
  t,
}: {
  rating: number
  userName: string
  createdAt: string
  imgUrl: string
  t: any
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '0.5rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          columnGap: '0.75rem',
        }}
      >
        <img height={38} width={38} src={imgUrl} alt={'Random User Image'} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text variant='body_m_500'> {userName}</Text>
          <Text variant='body_m_500' color='grayscale-400'>
            {createdAt}
          </Text>
        </div>
      </div>
      <RatingStars rating={rating} showText={false} t={t} />
    </div>
  )
}

export default UserInfoBar
