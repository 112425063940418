import type { MaterialTocChapterType, OnPreviewUnitClickType } from '../types'

import React, { useMemo } from 'react'

import themeVar from '../../../theme/themeVar'

import { Accordion } from '../../accordion'
import ChapterSectionTitle from './chapterSectionTitle'
import { processMaterialChapter } from '../utils'
import TocUnit from './tocUnit'

interface TocChapterProps {
  chapter: MaterialTocChapterType
  numberOfSectionsDefaultOpen: number
  onPreviewUnitClick?: OnPreviewUnitClickType
  t: any
  materialId: string
  subjectLanguage?: string
}

const ChapterWithAccordion = ({
  chapter,
  numberOfSectionsDefaultOpen,
  onPreviewUnitClick,
  t,
  materialId,
  subjectLanguage,
}: TocChapterProps) => {
  const sections = useMemo(() => processMaterialChapter(chapter), [chapter.children])

  return (
    <div style={{ width: '100%' }}>
      {sections.map((section, sectionIdx) => {
        const { name: sectionName, children: units } = section
        const isLastItem = sections.at(-1) === section
        const divBottom = isLastItem ? 0 : themeVar.distance.m

        return (
          <Accordion
            title={<ChapterSectionTitle sectionIdx={sectionIdx} sectionName={sectionName} />}
            key={`${sectionName}-${sectionIdx}`}
            style={{
              padding: 0,
              marginBottom: divBottom,
              paddingBottom: divBottom,
              borderBottom: isLastItem ? 'none' : '1px solid #eeefef',
            }}
            defaultOpen={sectionIdx < numberOfSectionsDefaultOpen}
          >
            <TocUnit
              t={t}
              units={units}
              section={section}
              materialId={materialId}
              onPreviewUnitClick={onPreviewUnitClick}
              subjectLanguage={subjectLanguage}
            />
          </Accordion>
        )
      })}
    </div>
  )
}

export default ChapterWithAccordion
