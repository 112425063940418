import * as React from 'react'

interface LecturerLocale {
  Lecturer: {
    check_detail: string
  }
}

export interface Locales {
  en: LecturerLocale
  'zh-TW': LecturerLocale
}

const defaultLocales: Locales = {
  en: {
    Lecturer: {
      check_detail: 'Details',
    },
  },
  'zh-TW': {
    Lecturer: {
      check_detail: '了解更多',
    },
  },
}

export const LocalContext = React.createContext(defaultLocales['zh-TW'])

type PropTypes = React.PropsWithChildren<{
  defaultLang: 'zh-TW' | 'en'
  locales?: typeof defaultLocales
}>

export const LecturerLocalProvider: React.FC<PropTypes> = ({
  defaultLang,
  locales = defaultLocales,
  children,
}) => {
  const [lang, setLang] = React.useState(defaultLang)

  React.useEffect(() => {
    setLang(defaultLang)
  }, [defaultLang])

  return <LocalContext.Provider value={locales[lang]}>{children}</LocalContext.Provider>
}

export const useLecturerLocale = () => React.useContext(LocalContext)
