import React from 'react'

import constructLocale, { LangType } from '../../utils/locale-constructor'

export interface Props {
  lang: LangType
  className?: string
}

const locale = {
  en: {
    hint: 'Please complete the payment within the payment deadline. After the payment is successful, you can see the order details and redeem teaching materials in "Account > Purchase History > Order Details"!',
  },
  'zh-TW': {
    hint: '請在繳款期限內完成付款，付款成功後可以於「帳戶 > 購買記錄 > 訂單詳情」中看到訂單明細並兌換教材！',
  },
}

const { LocaleProvider, useLocaleContext } = constructLocale({
  locales: locale,
})

export const PayBeforeDeadlineInfo = ({ lang, className }: Props) => {
  return (
    <LocaleProvider defaultLang={lang}>
      <Content className={className} />
    </LocaleProvider>
  )
}

const Content = ({ className }: { className?: string }) => {
  const { hint } = useLocaleContext()
  return (
    <div className={className}>
      <span>{hint}</span>
    </div>
  )
}
