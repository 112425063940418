import React from 'react'

import { Accordion } from '../accordion'

interface FaqItemProps {
  faqNumber: number
  title: string
  content: string
  children?: React.ReactNode
  renderImage?: () => React.ReactNode
}

export const FaqItem = ({ faqNumber, title, content, children, renderImage }: FaqItemProps) => {
  return (
    <Accordion title={`Q${faqNumber} ${title}`} content={content}>
      <>
        {children}
        {renderImage ? renderImage() : null}
      </>
    </Accordion>
  )
}
