import styled from '@emotion/styled'
import * as React from 'react'
import { useForm } from 'react-hook-form'

import Button from '../atoms/button'
import ErrorMessage from '../atoms/error-message'
import FormControl from '../atoms/form-control'
import Input from '../atoms/input'
import EmailInput from './email-input'
import { SignInFormData, SignUpFormData } from './index'
import { useLocale } from './local-provider'

const defaultValues = {
  name: '',
  email: '',
  password: '',
}

interface PropTypes {
  termUrl?: string
  handleAuth: (data: SignInFormData | SignUpFormData) => void
}

const SignUpForm = ({ termUrl, handleAuth }: PropTypes) => {
  const { name, password, signup, error, loading, privacy } = useLocale()
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues,
  })

  const onSubmit = async (data: SignInFormData) => {
    await handleAuth(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={Boolean(errors.name)}>
        <Input placeholder={name.plhr} {...register('name', { required: error.required })} />
      </FormControl>
      {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}

      <FormControl isInvalid={Boolean(errors.email)}>
        <EmailInput register={register} />
      </FormControl>
      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}

      <FormControl isInvalid={Boolean(errors.password)}>
        <Input
          placeholder={password.plhr}
          type='password'
          {...register('password', {
            required: error.required,
            minLength: { value: 6, message: error.password || '' },
          })}
        />
      </FormControl>
      {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}

      {termUrl && (
        <PrivacyHint>
          <p>{privacy.message}</p>
          <a href={termUrl} target='_blank' rel='noopener noreferrer'>
            {privacy?.linkText}
          </a>
        </PrivacyHint>
      )}

      <Button type='submit' disabled={isSubmitting} style={{ marginTop: '1rem' }}>
        {isSubmitting ? loading : signup.submit}
      </Button>
    </form>
  )
}

export default SignUpForm

const PrivacyHint = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem;
  margin-top: 0.5rem;

  p {
    margin: 0;
  }
  a {
    color: #72afff;
  }
`
