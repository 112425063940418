import useGlobalLoader from '@/hooks/use-global-loader'
import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const GlobalLoader = () => {
  const { isLoading } = useGlobalLoader()
  return (
    <Backdrop className='text-grayscale-000 z-[99999999999]' open={isLoading}>
      <CircularProgress color='primary' />
    </Backdrop>
  )
}

export default GlobalLoader
