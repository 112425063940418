import React from 'react'

import { getProgressPercentage } from './utils'

import ProgressLeftText from './components/progress-left-text'
import ProgressRightText from './components/progress-right-text'
import { Icon } from '../icon'
import Progressbar from '../progressbar'

import themeVar from '../../theme/themeVar'

const COLORS = themeVar.color.palette

type ProductCountdownType = {
  style: CountdownStyle
  endsAt: string
  name: string
  currentCount?: number | null
  totalCount?: number | null
}

type CountdownStyle = 'crowdfunding' | 'limited_time_offer' | 'limited_qty_offer' | 'before_on_sale'

interface PromotionCountdownPropTypes {
  countdown: ProductCountdownType | null
  title: React.ReactNode
  countdownDesc: string
  children?: React.ReactNode
  t: any
  titleStyle?: React.CSSProperties
}

const progressAllowedStyles: CountdownStyle[] = ['limited_qty_offer', 'crowdfunding']

export const PromotionCountdown = ({
  countdown,
  title,
  countdownDesc,
  children,
  t,
}: PromotionCountdownPropTypes) => {
  const progressProps =
    countdown && progressAllowedStyles.includes(countdown.style)
      ? {
          currentBuyerCount: Number(countdown.currentCount),
          goalBuyerCount: Number(countdown.totalCount),
          style: countdown.style,
        }
      : undefined

  const isLimitedQuantitySoldOut =
    countdown &&
    countdown.style === 'limited_qty_offer' &&
    countdown.currentCount &&
    countdown.totalCount &&
    countdown.currentCount >= countdown.totalCount

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'start',
          gap: '0.5rem',
          fontSize: '0.875rem',
          fontWeight: 500,
          lineHeight: '24.5px',
          letterSpacing: '0.042px',
          color: COLORS['peach-500'],
        }}
      >
        <Icon type='directions_run' />

        {title}
      </div>
      {progressProps && (
        <div
          style={{
            marginBottom: '5px',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <ProgressLeftText {...progressProps} t={t} />

            <ProgressRightText {...progressProps} t={t} />
          </div>

          <Progressbar
            bgColor={isLimitedQuantitySoldOut ? 'peach-300' : 'peach-500'}
            percentage={getProgressPercentage({
              currentBuyerCount: progressProps.currentBuyerCount,
              goalBuyerCount: progressProps.goalBuyerCount,
            })}
          />
        </div>
      )}

      <div
        style={{
          marginTop: '3px',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: '0.75rem',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
          <img
            alt='countdown'
            width={20}
            height={20}
            src='https://public.wordup.com.tw/shop/assets/animation_timer.gif'
          />
          <div
            style={{
              fontSize: '0.75rem',
              fontWeight: 500,
              lineHeight: '21px',
              letterSpacing: '0.06px',
              color: COLORS['grayscale-800'],
            }}
          >
            {countdownDesc}
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
