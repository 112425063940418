import styled from '@emotion/styled'
import * as React from 'react'

import { useLocale } from './local-provider'
import { AuthType } from './types'

export const HintContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;

  button {
    border: none;
    cursor: pointer;
    box-shadow: none;
    color: #72afff;
    text-decoration: underline;
    background: none;
    outline: none;
  }
`

interface TitleProps {
  layout: AuthType
  setLayout: (mode: AuthType) => void
}

const FormTitle: React.FC<TitleProps> = ({ layout, setLayout }) => {
  const { signin, signup } = useLocale()

  return (
    <HintContainer>
      <span>{layout === 'sign-in' ? signin?.hintTxt : signup?.hintTxt}</span>

      <button type='button' onClick={() => setLayout(layout === 'sign-in' ? 'sign-up' : 'sign-in')}>
        {layout === 'sign-in' ? signin?.hintBtn : signup?.hintBtn}
      </button>
    </HintContainer>
  )
}

export default FormTitle
