import styled from '@emotion/styled'
import * as React from 'react'
import Stars from 'react-stars'

const Container = styled.div`
  width: 350px;
  border-radius: 8px;
  backdrop-filter: blur(30px);
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px;
  padding: 16px 20px;

  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    color: #383b3d;
    margin-bottom: 4px;
  }
  h2 {
    color: #ababab;
    font-size: 14px;
    line-height: 1.75;
    margin-bottom: 4px;
  }

  .rating {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 14px;
    margin-bottom: 4px;

    .staring {
      display: inline-block;
      margin-right: 2px;
    }

    span {
      display: inline-block;
      margin-right: 2px;
    }
  }

  .tags {
    overflow: hidden;
    max-height: 58px;
    color: #42badb;
    font-size: 12px;
    margin-bottom: 4px;

    .tag {
      display: inline-block;
      margin-right: 6px;
      margin-bottom: 6px;
      padding: 4px 8px;
      border-radius: 4px;
      background-color: #f2f2f2;
    }
  }
`

export interface SidePanelProps {
  data: {
    name: string
    slogan?: string
    tags: string[]
    learn_with: string | null
    copies_sold: number

    show_rating: boolean
    rating_count: number
    average_rating: number
  }
  header?: React.FC<{ learn_with: string | null; copies_sold: number }>
}

export const SidePanel: React.FC<React.PropsWithChildren<SidePanelProps>> = ({
  data,
  header,
  children,
}) => {
  return (
    <Container>
      {header &&
        header({
          learn_with: data.learn_with,
          copies_sold: data.copies_sold,
        })}

      <h1>{data.name}</h1>

      {data.slogan && <h2>{data.slogan}</h2>}

      {data.show_rating && (
        <div>
          <div className="staring">
            <Stars
              edit={false}
              count={5}
              value={data.average_rating || 0}
              size={14}
              color1="#e5e5e5"
              color2="#ffc914"
            />
          </div>
          ({data.rating_count})
        </div>
      )}

      {data.tags && (
        <div className="tags">
          {data.tags.map((tag) => (
            <div key={tag} className="tag">
              #{tag}
            </div>
          ))}
        </div>
      )}

      {children}
    </Container>
  )
}
