import React, { memo } from 'react'

import { Icon } from '../icon'
import { Text } from '../text'

const ratings = [1, 2, 3, 4, 5]

function RatingStars({
  rating,
  ratingCount,
  showText,
  style,
  t,
}: {
  rating: number
  ratingCount?: number
  showText: boolean
  style?: React.CSSProperties
  t: any
}) {
  const ratingToFixed = typeof rating === 'number' ? rating.toFixed(1) : '0'

  const getStarIcon = (lv: number) => {
    // 0    ~ 0.2   => round down
    // 0.21 ~ 0.79  => 0.5
    // 0.8  ~ 0.99  => round up
    if (Number(ratingToFixed) >= lv - 0.2 && Number(ratingToFixed) < lv + 0.2) {
      return 'star_filled'
    }
    if (Number(ratingToFixed) > lv - 0.8 && Number(ratingToFixed) < lv - 0.2) {
      return 'star_half'
    }
    if (Number(ratingToFixed) > lv) {
      return 'star_filled'
    }
    return 'star'
  }

  const renderTexts = () => {
    if (!showText) return null

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
        <Text variant='body_m_500'>
          {rating ? ratingToFixed : `(${t('crowdin:feedback.rating.collecting')})`}
        </Text>
        {Boolean(ratingCount) && <Text variant='body_m_500'>{`(${ratingCount})`}</Text>}
      </div>
    )
  }
  return (
    <div style={style}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
          {ratings.map(lv => {
            const icon = getStarIcon(lv)
            return <Icon key={lv} type={icon} style={{ color: '#ffc914' }} />
          })}
        </div>
        {renderTexts()}
      </div>
    </div>
  )
}

export default memo(RatingStars)
