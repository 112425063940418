import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'

import themeVar from '../../theme/themeVar'
import { Icon } from '../icon'
import { Text } from '../text'

export interface AccordionProps {
  title: string | React.ReactNode
  style?: React.CSSProperties
  children?: React.ReactNode
  content?: string
  defaultOpen?: boolean
  className?: string
  bodyClassName?: string
  onChange?: (isOpen: boolean) => void
}

export const Accordion = ({
  title,
  style,
  children,
  content,
  defaultOpen = false,
  className,
  bodyClassName,
  onChange,
}: AccordionProps) => {
  const [showBody, setShowBody] = useState<boolean>(defaultOpen)
  const detailRef = useRef<HTMLDetailsElement>(null)

  useEffect(() => {
    if (defaultOpen && detailRef.current) {
      detailRef.current.open = true
    }
  }, [])

  return (
    <AccordionContainer className={className} style={style} ref={detailRef}>
      <AccordionTitle
        onClick={() => {
          setShowBody(prev => {
            onChange && onChange(!prev)
            return !prev
          })
        }}
      >
        <Text variant='body_m_500'>{title}</Text>
        <IconContainer>
          <Icon
            type='keyboard_arrow_up'
            size={24}
            style={{
              transform: showBody ? 'none' : 'rotate(180deg)',
              transition: '0.15s',
            }}
          />
        </IconContainer>
      </AccordionTitle>

      <AccordionBody className={bodyClassName}>
        <ContentContainer>
          {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
          {children}
        </ContentContainer>
      </AccordionBody>
    </AccordionContainer>
  )
}

const AccordionContainer = styled.details`
  list-style: none;
  border-bottom: 1px solid ${themeVar.color.palette['grayscale-100']};
  line-height: 1.5;
  padding: ${themeVar.distance.m};
`

const ContentContainer = styled.div`
  font-size: ${themeVar.fontSize.sm};
  color: ${themeVar.color.palette['grayscale-800']};
  line-height: 175%;
`

const IconContainer = styled.div`
  height: ${themeVar.distance.l};
  width: ${themeVar.distance.l};
  margin-left: ${themeVar.distance.s};
  display: flex;
  align-items: center;
  justify-content: center;
`

const AccordionBody = styled.div`
  margin-top: ${themeVar.distance.sm};
  max-height: auto;
  overflow: visible;
  & a {
    color: ${themeVar.color.palette['primary-500']};
  }
`

export const AccordionTitle = styled.summary`
  background-color: transparent;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  list-style: none;

  &::-webkit-details-marker {
    display: none;
  }
`
