import * as React from 'react'

interface CourseOpenInfoLocale {
  open_course: {
    title: string
    open_at: string
    fully_open_at: string
  }
}

export interface Locales {
  en: CourseOpenInfoLocale
  'zh-TW': CourseOpenInfoLocale
}

const defaultLocales: Locales = {
  en: {
    open_course: {
      title: 'Open Course Reminders',
      open_at: 'open',
      fully_open_at: 'fully open！',
    },
  },
  'zh-TW': {
    open_course: {
      title: '課程開放提醒',
      open_at: '正式開課',
      fully_open_at: '後開放全部課程！',
    },
  },
}

export const LocalContext = React.createContext(defaultLocales['zh-TW'])

type PropTypes = React.PropsWithChildren<{
  defaultLang: 'zh-TW' | 'en'
  locales?: typeof defaultLocales
}>

export const OpenCourseLocalProvider: React.FC<PropTypes> = ({
  defaultLang,
  locales = defaultLocales,
  children,
}) => {
  const [lang, setLang] = React.useState(defaultLang)

  React.useEffect(() => {
    setLang(defaultLang)
  }, [defaultLang])

  return <LocalContext.Provider value={locales[lang]}>{children}</LocalContext.Provider>
}

export const useOpenCourseLocale = () => React.useContext(LocalContext)
