import type { PaymentMethod } from '@/features/payment/types'
import type { CheckoutApiRequestPayload } from './types'
import type { CheckoutErrorCode } from './checkout-types'
import { isEmpty } from 'lodash-es'
import { REFERRAL_CODE_KEY } from '@/constants/product'
import { PIXEL_CONVERSION_API_PARAMS } from '@/features/tracking/pixel'
import { GOOGLE_CONVERSION_API } from '@/features/tracking/gtag'
import cookieLib from '@/libs/cookie'

export const EXPECTED_ERROR_CODES: CheckoutErrorCode[] = [
  'already_claimed',
  'already_trial',
  'course_already_redeemed',
  'payment_gateway_failed',
  'coupon_no_left',
]

export const checkout = ({
  lineItems,
  couponCode,
  currency = 'TWD',
  paymentMethod,
  paymentDetails = {},
  b2bReceipt = {},
  b2bOrderInfo,
  utm = {},
  usermobile,
}: {
  lineItems: any[]
  couponCode?: string
  currency?: string
  paymentMethod: PaymentMethod
  paymentDetails?: object
  b2bReceipt?: { address?: string; taxIdNumber?: string; companyName?: string }
  b2bOrderInfo?: {
    orgId: string
    staffCode: string
    staffName: string
    departmentName: string
    mobile: string
  }
  utm?: Record<string, string>
  usermobile?: string
}) => {
  const payload: CheckoutApiRequestPayload = {
    line_items: lineItems,
    coupon_code: couponCode,
    currency,
    payment_method: paymentMethod,
    payment_details: paymentDetails,
    b2b_receipt: {
      address: b2bReceipt?.address,
      tax_id_number: b2bReceipt?.taxIdNumber,
      company_name: b2bReceipt?.companyName,
    },
    /** @deprecated Migrate to event_meta.utm */
    utm: utm,
    user_mobile: usermobile,
    [REFERRAL_CODE_KEY]: window.sessionStorage.getItem(REFERRAL_CODE_KEY),
    event_meta: {
      utm,
      fbp: cookieLib.get('_fbp', undefined, false) as string | undefined,
      fbc: (cookieLib.get('_fbc', undefined, false) ||
        cookieLib.get(PIXEL_CONVERSION_API_PARAMS)) as string | undefined,
      event_source_url: `${process.env.NEXT_PUBLIC_SELF_DOMAIN}${window.location.pathname}`,
      ...(cookieLib.get(GOOGLE_CONVERSION_API.KEY, true) as Object | null | undefined),
    },
  }

  if (!isEmpty(b2bOrderInfo)) {
    payload.b2b_order_info = {
      org_id: b2bOrderInfo.orgId,
      staff_code: b2bOrderInfo.staffCode,
      staff_name: b2bOrderInfo.staffName,
      department_name: b2bOrderInfo.departmentName,
      mobile: b2bOrderInfo.mobile,
    }
  }

  return {
    url: '/v1/shop/checkout',
    method: 'POST',
    data: { data: payload },
  }
}

export const fetchDefaultPaymentMethod = () => ({
  url: `/v1/payment_methods/default`,
  method: 'GET',
})
