import React from 'react'

import constructLocale, { LangType } from '../../utils/locale-constructor'

import styled from '@emotion/styled'
import themeVar from '../../theme/themeVar'
import { OrderInfoAccordion } from './accordion'

const OrderHistoryLink = 'https://app.wordup.com.tw/account/order-history'

export interface AfterPurchaseTutorialProps {
  lang: LangType
}

const locale = {
  en: {
    how_to_learn_after_purchase: 'How to start learning after purchase?',
    for_self_usage:
      'Bind to current account: The materials will be immediately delivered to your account upon order completion, allowing you to start learning right away!',
    redemption_code:
      'Redemption code: Enter the redemption code in WORD UP APP or website to redeem the material. Each redemption code can only be used once. Please keep it carefully!',
    desc: {
      pre: "After successful payment, you can synchronize the order details in your account's ",
      link_text: 'order history',
      post: ' and view the information.',
    },
  },
  'zh-TW': {
    how_to_learn_after_purchase: '購買後如何開始學習？',
    for_self_usage:
      '綁定目前帳號：教材將在訂單完成後立即派送至您現在登入的帳號，馬上就能開始學習！',
    redemption_code:
      '教材兌換碼：於 WORD UP APP 或網頁輸入兌換碼即可兌換教材，每組兌換碼僅限使用一次，請小心保管與轉贈！',
    desc: {
      pre: '付款成功後，您可以同步於帳戶的',
      link_text: '購買紀錄',
      post: '中看到訂單詳細資訊！',
    },
  },
}

const { LocaleProvider, useLocaleContext } = constructLocale({
  locales: locale,
})

export const AfterPurchaseTutorial = (props: AfterPurchaseTutorialProps) => {
  const { lang } = props
  return (
    <LocaleProvider defaultLang={lang}>
      <Content />
    </LocaleProvider>
  )
}

const Content = () => {
  const { how_to_learn_after_purchase, desc, for_self_usage, redemption_code } = useLocaleContext()
  return (
    <OrderInfoAccordion
      title={how_to_learn_after_purchase}
      diamondIconColor='yellow-500'
      defaultOpen={true}
    >
      <Container>
        <StyledUl>
          <li>{for_self_usage}</li>
          <li>{redemption_code}</li>
        </StyledUl>
        {desc.pre}
        <a href={OrderHistoryLink}>{desc.link_text}</a>
        {desc.post}
      </Container>
    </OrderInfoAccordion>
  )
}

const StyledUl = styled.ul`
  margin: 0;
  list-style-type: disc;
  padding-inline-start: 20px;
`
const Container = styled.div`
  letter-spacing: 0.0025em;
  line-height: 175%;
  font-size: ${themeVar.fontSize.sm};
  font-weight: 400;
`
