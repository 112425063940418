import React from 'react'
import { Text } from '../../text'
import { TocType } from '../types'
import { gradientTextStyle } from '../constants'

const MaterialName = ({ children, tocType }: { children: React.ReactNode; tocType: TocType }) => {
  return (
    <Text
      variant='label_s_400'
      component='h4'
      color='grayscale-500'
      style={{
        ...(tocType === 'free' ? gradientTextStyle : {}),
      }}
    >
      {children}
    </Text>
  )
}

export default MaterialName
