import styled from '@emotion/styled'
import React from 'react'

import themeVar from '../../theme/themeVar'
import constructLocale, { LangType } from '../../utils/locale-constructor'
import { WuModal, WuModalProps } from '../modal'

export interface TestimonyModalProps
  extends Omit<WuModalProps, 'maxWidth' | 'padding' | 'children'> {
  recommenderName: string
  recommenderTitles?: string[]
  longTestimonial: string
  largeImageUrl: string
  showPrevButton: boolean
  showNextButton: boolean
  prevButtonOnClick: () => void
  nextButtonOnClick: () => void
  lang: LangType
}

const locale = {
  en: {
    prev: 'Previous',
    next: 'Next',
  },
  'zh-TW': {
    prev: '上一篇',
    next: '下一篇',
  },
}

const { LocaleProvider, useLocaleContext } = constructLocale({
  locales: locale,
})

export const TestimonyModal = ({
  recommenderName,
  recommenderTitles,
  longTestimonial,
  largeImageUrl,
  showPrevButton,
  showNextButton,
  prevButtonOnClick,
  nextButtonOnClick,
  lang,
  ...restProps
}: TestimonyModalProps) => {
  return (
    <LocaleProvider defaultLang={lang}>
      <WuModal
        {...restProps}
        maxWidth='500px'
        padding='0'
        bottomComp={
          <BottomComp
            showNextButton={showNextButton}
            showPrevButton={showPrevButton}
            prevButtonOnClick={prevButtonOnClick}
            nextButtonOnClick={nextButtonOnClick}
          />
        }
      >
        <ContentContainer>
          <StyledImg src={largeImageUrl} alt={`${recommenderName}`} />
          <TextContainer>
            <NameContainer>{recommenderName}</NameContainer>
            {recommenderTitles && recommenderTitles.length > 0 && (
              <TitlesContainer>
                {recommenderTitles.map((title, index) => (
                  <div key={`${index}_${title}`}>{title}</div>
                ))}
              </TitlesContainer>
            )}
            <TestimonialText dangerouslySetInnerHTML={{ __html: longTestimonial }} />
          </TextContainer>
        </ContentContainer>
      </WuModal>
    </LocaleProvider>
  )
}

const BottomComp = ({
  showPrevButton,
  showNextButton,
  prevButtonOnClick,
  nextButtonOnClick,
}: {
  showPrevButton: boolean
  showNextButton: boolean
  prevButtonOnClick: () => void
  nextButtonOnClick: () => void
}) => {
  const { next, prev } = useLocaleContext()
  return (
    <BottomCompContainer>
      <div>
        {showPrevButton && <StyledButton onClick={prevButtonOnClick}>← {prev}</StyledButton>}
      </div>
      <div>
        {showNextButton && <StyledButton onClick={nextButtonOnClick}>{next} →</StyledButton>}
      </div>
    </BottomCompContainer>
  )
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 200px);
  overflow: auto;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
`

const NameContainer = styled.div`
  display: flex;
  font-size: ${themeVar.fontSize.l};
  font-weight: bold;
  color: ${themeVar.color.palette['grayscale-1100']};
`

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${themeVar.fontSize.s};
  font-weight: 500;
  color: ${themeVar.color.palette['grayscale-800']};
  border-left: solid 2px ${themeVar.color.palette['grayscale-800']};
  margin-top: ${themeVar.distance.xs};
  padding-left: ${themeVar.distance.s};
  line-height: 1.8;
`
const TestimonialText = styled.div`
  margin-top: ${themeVar.distance.m};
  color: ${themeVar.color.palette['grayscale-500']};
  font-size: ${themeVar.fontSize.sm};
  font-weight: normal;
  line-height: 1.64;
  letter-spacing: 1.17px;
  white-space: pre-line;
`

const StyledImg = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 500/253;
  object-fit: cover;
`

const BottomCompContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  line-height: 1.44;
  letter-spacing: 1.33px;
  font-weight: 500;
  font-size: ${themeVar.fontSize.m};
  margin-top: ${themeVar.distance.xs};
`

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  color: white;
  margin: 0 ${themeVar.distance.sm};
  cursor: pointer;
  &:focus {
    outline: none;
  }
`
