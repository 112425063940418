import type { RedeemCode } from '..'
import React from 'react'

import styleVars from '../../../../theme/themeVar'

type Props = {
  titleInfo: string
  redeemCode: RedeemCode
  t: any
}
const STYLE: React.CSSProperties = {
  margin: 0,
  padding: 0,
  color: styleVars['color']['palette']['grayscale-500'],
  fontSize: styleVars.fontSize.s,
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '0.06px',
  marginBottom: '0.5rem',
}
const RedeemCodeTitle = ({ titleInfo, redeemCode, t }: Props) => {
  switch (redeemCode.materialExpiryKind) {
    case 'na': {
      return (
        <h5 style={STYLE}>
          {t('crowdin:wu-components.line-item-info.redeem-code.title--infinite')}
        </h5>
      )
    }
    case 'exact_time': {
      const expireTime = titleInfo
      if (!expireTime) return null

      return (
        <h5
          style={{
            ...STYLE,
            color: styleVars['color']['palette']['peach-500'],
          }}
        >
          {t('crowdin:wu-components.line-item-info.redeem-code.title--exact-time', { expireTime })}
        </h5>
      )
    }
    case 'period_after_enrolment': {
      const [value, uint] = titleInfo.split('-')
      const period = (() => {
        switch (uint) {
          case 'months': {
            return `${value} ${t('crowdin:common.unit--quantity')}${t(
              'crowdin:common.unit--month',
            )}`
          }
          case 'days': {
            return `${value} ${t('crowdin:common.unit--date')}`
          }
          default:
            return null
        }
      })()

      if (!period) return null

      return (
        <h5 style={STYLE}>
          {t('crowdin:wu-components.line-item-info.redeem-code.title--period-after-enrolment', {
            period,
          })}
        </h5>
      )
    }
    default:
      return null
  }
}

export default RedeemCodeTitle
