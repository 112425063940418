import type { LineItemInfoModalBodyProps } from '.'
import React from 'react'

import { BUTTON_RESET_STYLE } from '../constants'
import { Icon } from '../../../../icon'

const SelfModalBody = ({ clickMap, t }: LineItemInfoModalBodyProps) => {
  return (
    <div
      style={{
        textAlign: 'center',
        color: 'rgb(56,59,61)', // text-grayscale-800
      }}
    >
      <Icon
        type='error'
        size={60}
        style={{
          color: '#BDC0C0', // Grayscale-300
          margin: '0 auto 8px',
        }}
      />

      <div
        style={{
          fontSize: '1.5rem',
          lineHeight: '2rem', // text-2xl
          marginBottom: 8,
        }}
      >
        {t('crowdin:wu-components.line-item-info.redeem-code.self-popup.title')}
      </div>
      <div
        style={{
          fontSize: '1rem',
          lineHeight: '1.5rem', // text-base
          color: 'rgb(157,161,161)', // grayscale-400
          marginBottom: 4,
        }}
      >
        {t('crowdin:wu-components.line-item-info.redeem-code.self-popup.description')}
      </div>
      <div
        style={{
          fontSize: '0.75rem',
          lineHeight: '1rem', // text-xs
          color: '#F06449', // peach-400
          marginBottom: 12,
        }}
      >
        {t('crowdin:wu-components.line-item-info.redeem-code.gift-popup.notice')}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 12,
        }}
      >
        <button
          type='button'
          style={{
            ...BUTTON_RESET_STYLE,
            flex: 1,
            height: 36,
            color: 'black',
            backgroundColor: 'rgb(238,239,239)', // bg-grayscale-100
            borderRadius: 18,
          }}
          onClick={clickMap?.onClose}
        >
          {t('crowdin:common.cancel')}
        </button>
        <button
          type='button'
          style={{
            ...BUTTON_RESET_STYLE,
            flex: 1,
            height: 36,
            color: 'white',
            backgroundColor: 'rgb(114,175,255)', //bg-primary-500
            borderRadius: 18,
          }}
          onClick={clickMap?.onConfirm}
        >
          {t('crowdin:common.confirm')}
        </button>
      </div>
    </div>
  )
}

export default SelfModalBody
