const path = require('path')

const LOCALE_TW = 'zh-TW'
const LOCALE_EN = 'en'

const localeSubpaths = {
  [LOCALE_TW]: LOCALE_TW,
  [LOCALE_EN]: LOCALE_EN,
}

module.exports = {
  i18n: {
    locales: [LOCALE_TW, LOCALE_EN],
    defaultLocale: LOCALE_TW,
  },
  localePath: path.resolve('./public/locales'),
  LOCALE_TW,
  LOCALE_EN,
  localeSubpaths,
  reloadOnPrerender: process.env.NEXT_PUBLIC_STAGE !== 'production',
}
