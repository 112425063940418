import React from 'react'
import themeVar from '../../../theme/themeVar'
import { Text } from '../../text'

type Props = {
  sectionIdx: number
  sectionName: string
}
const ChapterSectionTitle = ({ sectionIdx, sectionName }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <h6
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          margin: 0,
          fontSize: themeVar.fontSize.sm,
          fontWeight: 400,
          lineHeight: '24.5px',
          letterSpacing: '0.035px',
        }}
      >
        <span
          style={{
            backgroundColor: themeVar.color.palette['grayscale-100'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            marginRight: themeVar.distance.s,
            flexShrink: 0,
          }}
        >
          {sectionIdx + 1}
        </span>
        <Text variant='body_m_400' color='grayscale-800'>
          {sectionName}
        </Text>
      </h6>
    </div>
  )
}

export default ChapterSectionTitle
