import styled from '@emotion/styled'
import { Modal, ModalProps } from '@mui/material'
import React from 'react'
import { Icon } from '../icon'
import { Button } from '../button'

import themeVar from '../../theme/themeVar'

export interface WuModalProps extends ModalProps {
  hideCloseButton?: boolean
  padding?: string
  maxWidth?: string
  maxHeight?: string
  bottomComp?: React.ReactNode
  closeIconStyles?: React.CSSProperties
  mainBlockStyles?: Omit<React.CSSProperties, 'maxHeight'>
}

export const WuModal = (props: WuModalProps) => {
  const {
    hideCloseButton,
    padding = '1.875rem',
    maxWidth,
    bottomComp,
    closeIconStyles,
    maxHeight = '100%',
    mainBlockStyles,
    disableAutoFocus,
    ...restProps
  } = props

  return (
    <StyleModal aria-label='modal' {...restProps} disableAutoFocus={disableAutoFocus ?? true}>
      <OuterDiv style={{ maxWidth }}>
        <InnerDiv>
          {!hideCloseButton && (
            <CloseButtonContainer>
              <Button
                style={{ color: themeVar.color.palette['grayscale-000'] }}
                onClick={e => {
                  restProps.onClose && restProps.onClose(e, 'backdropClick')
                }}
              >
                <Icon type='close' style={closeIconStyles} size={24} />
              </Button>
            </CloseButtonContainer>
          )}
          <MainBlock maxHeight={maxHeight} style={{ ...mainBlockStyles, padding }}>
            {restProps.children}
          </MainBlock>
          {bottomComp}
        </InnerDiv>
      </OuterDiv>
    </StyleModal>
  )
}

const StyleModal = styled(Modal)`
  .MuiBackdrop-root {
    background-color: rgba(56, 59, 61, 0.9);
    opacity: 0.9;
  }
`

const OuterDiv = styled.div`
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 1rem;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`

const InnerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const MainBlock = styled.div`
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border-radius: 0.5rem;
  background-color: ${themeVar.color.palette['grayscale-000']};
  transform: translateZ(0);
  width: 100%;
  height: 100%;
  max-height: ${({ maxHeight }: { maxHeight: string }) => `calc(${maxHeight} - 1.5rem - 0.25rem)`};
  overflow: scroll;

  // Hidden scrollbar
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const CloseButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: ${themeVar.distance.xs};
`
