import styled from '@emotion/styled'
import React from 'react'

import themeVar from '../../theme/themeVar'
import constructLocale, { LangType } from '../../utils/locale-constructor'

import { TitleWithIcon } from './title-with-icon'

export interface NeedHelpProps {
  lang: LangType
}

const customerServiceEmail = 'mailto:service@wordup.com.tw'

const locale = {
  en: {
    need_help: 'Need Help?',
    if_you_have_any_questions: 'If you have any questions, please email us at',
    common_faq_after_purchase: 'service@wordup.com.tw',
    to_get_help: 'or use the chat function to contact customer service!',
  },
  'zh-TW': {
    need_help: '需要協助嗎？',
    if_you_have_any_questions: '如果您遇到問題，可以來信',
    common_faq_after_purchase: 'service@wordup.com.tw',
    to_get_help: '或利用聊聊功能與客服聯繫！',
  },
}

const { LocaleProvider, useLocaleContext } = constructLocale({
  locales: locale,
})

export const NeedHelp = (props: NeedHelpProps) => {
  const { lang } = props
  return (
    <LocaleProvider defaultLang={lang}>
      <Content />
    </LocaleProvider>
  )
}

const Content = () => {
  const { need_help, if_you_have_any_questions, common_faq_after_purchase, to_get_help } =
    useLocaleContext()
  return (
    <Container>
      <TitleWithIcon title={need_help} diamondIconColor='primary-500' />

      <TextContainer>
        <span>{if_you_have_any_questions}</span>
        <a href={customerServiceEmail} target='_blank'>
          {common_faq_after_purchase}
        </a>
        <span>{to_get_help}</span>
      </TextContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeVar.distance.xs};
  width: 100%;
`

const TextContainer = styled.div`
  column-gap: ${themeVar.distance.xs};
  letter-spacing: 0.0025em;
  line-height: 175%;
  font-size: ${themeVar.fontSize.sm};
  font-weight: 400;

  a {
    padding: 0 0.25rem;
    color: ${themeVar.color.palette['primary-500']};
  }
`
