import styled from '@emotion/styled'
import React from 'react'

import themeVar from '../../theme/themeVar'

type DiamondColors = keyof typeof themeVar.color.palette

export interface DiamondDivProps {
  diamondIconColor: DiamondColors
}
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.01em;
  font-size: ${themeVar.fontSize.l};
`

const DiamondShape = styled.div<DiamondDivProps>`
  height: 8px;
  background-color: ${props => themeVar.color.palette[props.diamondIconColor]};
  transform: rotate(45deg);
  width: 8px;
`

export const TitleWithIcon = ({
  title,
  diamondIconColor,
}: {
  title: string
  diamondIconColor: DiamondColors
}) => {
  return (
    <TitleContainer>
      <DiamondShape diamondIconColor={diamondIconColor} />
      <div>{title}</div>
    </TitleContainer>
  )
}
