import styled from '@emotion/styled'
import FacebookLogin from '@greatsumini/react-facebook-login'
import AppleSignin from 'react-apple-signin-auth'

import { useGoogleLogin } from '@react-oauth/google'

import { isInPopularAppWebview } from '../../utils/webview'
import AppleLogo from './apple-logo.png'
import { IMAGES } from './images'
import { LoginTypes, SocialProviderType } from './types'

interface Props {
  onSuccess: (type: SocialProviderType, params: object) => void
  onError: (info: {
    type: LoginTypes
    errCode?: string
    msg?: string
    res?: unknown
    status?: number
  }) => void
  fbAppId?: string
  fbRedirectUri?: string
  fbState?: string
  googleClientId?: string
  appleClientId?: string
  appleRedirectUri?: string
}

const SocialSignIn = (props: Props) => {
  const {
    onSuccess,
    onError,
    fbAppId,
    fbRedirectUri,
    fbState,
    googleClientId,
    appleClientId,
    appleRedirectUri,
  } = props

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      const { access_token } = tokenResponse

      onSuccess('google', {
        user_info: { id_token: access_token },
      })
    },
    onError: res => {
      if (typeof onError === 'function') {
        onError({
          type: 'google',
          errCode: res?.error,
          msg: res?.error_description,
          res,
        })
      }
      return
    },
    onNonOAuthError: error => {
      if (
        error.type === 'popup_failed_to_open' ||
        error.type === 'popup_closed' ||
        error.type === 'unknown'
      ) {
        return
      }
    },
  })

  return (
    <>
      {fbAppId && (
        <FacebookLogin
          appId={fbAppId}
          fields='name,email'
          scope='public_profile,email'
          onFail={res => {
            if (res.status === 'loginCancelled') return

            if (typeof onError === 'function') {
              onError({ type: 'fb', errCode: res.status, res })
            }
          }}
          onSuccess={res => {
            if (!res?.accessToken) {
              if (typeof onError === 'function') {
                onError({ type: 'fb', res })
              }
              return
            }

            onSuccess('fb', { access_token: res.accessToken })
          }}
          dialogParams={{
            response_type: 'token',
            redirect_uri: fbRedirectUri || '',
            state: fbState || '',
          }}
          render={({ onClick }) => (
            <SocialProviderBtn onClick={onClick}>
              <img src={IMAGES.FacebookLogo} alt='Login with Facebook' loading='lazy' />
            </SocialProviderBtn>
          )}
        />
      )}
      {googleClientId && !isInPopularAppWebview() && (
        <SocialProviderBtn onClick={() => googleLogin()}>
          <img src={IMAGES.GoogleLogo} alt='Login with Google' loading='lazy' />
        </SocialProviderBtn>
      )}
      {appleClientId && (
        <AppleSignin
          authOptions={{
            clientId: appleClientId,
            scope: 'email name',
            /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
            redirectURI: appleRedirectUri || '/',
            /** State string that is returned with the apple response */
            // state: 'state',
            /** Nonce */
            // nonce: 'nonce',
            /** Uses popup auth instead of redirection */
            // usePopup: ${authOptions.usePopup},
          }}
          /** General props */
          // uiType="dark"
          /** className */
          // className="apple-auth-btn"
          /** Removes default style tag */
          // noDefaultStyle={false}
          /** Allows to change the button's children, eg: for changing the button text */
          // buttonExtraChildren="Continue with Apple"
          /** Extra controlling props */
          /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
          onSuccess={(res: any) => {
            console.log('onSuccess response', res)
            console.log(res)

            try {
              if (res.error && typeof onError === 'function') {
                return onError({
                  type: 'apple',
                  errCode: res.error.error,
                  msg: '',
                  res,
                })
              }

              const {
                authorization: { id_token: identityToken },
                user,
              } = res

              const name = user ? `${user.name?.lastName} ${user.name?.firstName}` : null

              return onSuccess('apple', {
                user_info: {
                  identityToken,
                  fullName: name ? { nickname: name } : {},
                },
              })
            } catch (err) {
              console.log('err', err)
            }
          }} // default = undefined
          /** Called upon signin error */
          onError={(error: any) => console.error(error)} // default = undefined
          /** Skips loading the apple script if true */
          // skipScript={false} // default = undefined
          /** Apple image props */
          // iconProp={{ style: { marginTop: '10px' } }} // default = undefined
          /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
          render={(props: any) => (
            <SocialProviderBtn {...props}>
              {/* @ts-expect-error -- the bundler in next will handled */}
              <img src={AppleLogo} alt='Login with Apple' loading='lazy' />
            </SocialProviderBtn>
          )}
        />
      )}
    </>
  )
}

export default SocialSignIn

const SocialProviderBtn = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: background-color 0.23s ease-in-out;

  img {
    width: 2.5rem;
    height: auto;
  }
`
