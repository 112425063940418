// * Base on WORD UP web design and structure
// * Make it only support cloudflare in first release version

// ? About picture:
// ? Reference: https://developer.mozilla.org/zh-TW/docs/Web/HTML/Element/picture#media_%E5%B1%AC%E6%80%A7

import * as React from 'react'

export interface SizeItem {
  width: number
  device: number
}

export interface ImgProps {
  alt?: string
  domain: string
  filePaths: string[]
  sizes: SizeItem[]
}

export const Img = React.memo<ImgProps>(({ alt = '', filePaths, domain, sizes }) => {
  return (
    <picture>
      {sizes.map((size, idx) => {
        const isLast = idx === sizes.length - 1
        const filePath = idx < sizes.length / 2 ? filePaths[1] : filePaths[0]

        return (
          <source
            key={size.device}
            media={`(${isLast ? 'max' : 'min'}-width: ${size.device}px)`}
            srcSet={`
              ${domain}/cdn-cgi/image/width=${size.width},format=auto,quality=75${filePath} 1x,
              ${domain}/cdn-cgi/image/width=${size.width},format=auto,quality=80,dpr=2${filePath} 2x,
            `}
          />
        )
      })}
      <img
        src={domain + filePaths[0]}
        alt={alt}
        loading='lazy'
        style={{ position: 'relative', width: '100%', height: 'auto' }}
      />
    </picture>
  )
})
