import React from 'react'

import './styles.css'
import themeVar from '../../theme/themeVar'

export type TypographyVariantsKeys =
  | 'headline_3xl_500'
  | 'headline_2xl_500'
  | 'headline_xl_500'
  | 'heading_l_500'
  | 'heading_m_500'
  | 'body_l_500'
  | 'body_m_500'
  | 'body_m_400'
  | 'body_s_500'
  | 'body_s_400'
  | 'label_s_500'
  | 'label_s_400'

export type ComponentType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p' | 'li' | 'div'

export interface TextPropsTypes
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  variant: TypographyVariantsKeys
  children?: React.ReactNode
  color?: keyof typeof themeVar.color.palette | 'inherit' | 'unset'
  component?: ComponentType
}
const getElement = (component: ComponentType): React.ElementType => {
  switch (component) {
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6':
    case 'p':
    case 'li':
    case 'div':
      return component
    case 'span':
    default:
      return 'span'
  }
}
export const Text = ({
  variant,
  style,
  children,
  className = '',
  color = 'grayscale-800',
  component = 'span',
  ...restProps
}: TextPropsTypes) => {
  const finalClassName = `${variant} ${className} ${component !== 'span' ? 'm-0 p-0' : ''}`.trim()

  const finalStyle =
    color === 'unset'
      ? style
      : {
          ...style,
          color: color === 'inherit' ? color : themeVar.color.palette[color],
        }

  const TextTag = getElement(component)

  return (
    <TextTag style={finalStyle} className={finalClassName} {...restProps}>
      {children}
    </TextTag>
  )
}
