import React, { useMemo } from 'react'

import themeVar from '../../theme/themeVar'
import { TocLocalProvider } from './localProvider'
import { TocProps } from './types'
import { getTocUnitCount } from './utils'

import MaterialNameComponent from './components/materialNameComponent'
import { Text } from '../text'
import ChapterWithAccordion from './components/chapterWithAccordion'
import Chapter from './components/chapter'

const TOC = ({ lang = 'zh-TW', ...restProps }: TocProps) => (
  <TocLocalProvider defaultLang={lang}>
    <TocComp {...restProps} />
  </TocLocalProvider>
)

const TocComp = ({
  materialsToc,
  className,
  numberOfSectionsDefaultOpen = 0,
  onPreviewUnitClick,
  t,
  tocType,
}: Omit<TocProps, 'lang'>) => {
  if (!materialsToc) return null

  const isBundleToc = materialsToc && materialsToc.length > 1

  const allChapterAndSectionCount = useMemo(() => {
    return materialsToc.map(material => ({
      chapterCounts:
        material.children?.reduce((acc, chapter) => (acc += chapter.children.length), 0) ?? 0,
      unitCounts: getTocUnitCount(material),
    }))
  }, [materialsToc])

  return (
    <ul
      style={{
        lineHeight: 1.5,
        margin: 0,
        padding: 0,
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: themeVar.distance.ml,
      }}
    >
      {materialsToc.map((material, index) => {
        const { name: materialName, children: chapters, subjectLanguage } = material

        const chapterAndSectionCounts = t(
          'crowdin:product-detail-page.toc-chapter-and-section-counts',
          {
            chapters: allChapterAndSectionCount[index].chapterCounts,
            sections: allChapterAndSectionCount[index].unitCounts,
          },
        )

        return (
          <li
            key={materialName}
            className={className}
            style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}
          >
            {isBundleToc && (
              <MaterialNameComponent tocType={tocType}>
                {tocType === 'all' ? `${materialName} | ${chapterAndSectionCounts}` : materialName}
              </MaterialNameComponent>
            )}
            {!isBundleToc && tocType === 'all' && (
              <MaterialNameComponent tocType={tocType}>
                {chapterAndSectionCounts}
              </MaterialNameComponent>
            )}

            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: themeVar.distance.m,
                margin: 0,
                padding: 0,
                listStyle: 'none',
              }}
            >
              {chapters.map((chapter, chapterIdx) => {
                const { name: chapterName } = chapter

                return (
                  <li key={`${chapterName}_${chapterIdx}`}>
                    {tocType === 'all' && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          gap: '8px',
                        }}
                      >
                        <Text variant='body_m_500' component='h5'>
                          {chapterName}
                        </Text>
                        <ChapterWithAccordion
                          materialId={material.id}
                          key={`${chapterName}-${chapterIdx}`}
                          chapter={chapter}
                          numberOfSectionsDefaultOpen={numberOfSectionsDefaultOpen}
                          onPreviewUnitClick={onPreviewUnitClick}
                          t={t}
                          subjectLanguage={subjectLanguage}
                        />
                      </div>
                    )}

                    {tocType === 'free' && (
                      <Chapter
                        materialId={material.id}
                        key={`${chapterName}-${chapterIdx}`}
                        chapter={chapter}
                        onPreviewUnitClick={onPreviewUnitClick}
                        t={t}
                        subjectLanguage={subjectLanguage}
                      />
                    )}
                  </li>
                )
              })}
            </ul>
          </li>
        )
      })}
    </ul>
  )
}

export default TOC
