import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'

import { useAuth } from '@/features/auth'
import { getAnonymousId } from '@/features/tracking'
import { isWuAppWebview } from '@libs-components/utils/device'
import { getIntercomUserId } from './utils'

const Intercom = () => {
  const { shutdown: shutdownIntercom, boot: bootIntercom } = useIntercom()
  const { getUserId, userInfo } = useAuth()

  useEffect(() => {
    if (isWuAppWebview() || !bootIntercom) return

    const userId = String(getUserId() ?? getAnonymousId())
    bootIntercom({
      userId: getIntercomUserId(userId),
      name: userInfo?.name,
      email: userInfo?.email,
    })
  }, [bootIntercom, getUserId, userInfo])

  useEffect(() => {
    return () => {
      if (shutdownIntercom) shutdownIntercom()
    }
  }, [shutdownIntercom])

  return null
}

export default Intercom
