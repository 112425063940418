import { WuModal } from '../modal'

import React from 'react'
import themeVar from '../../theme/themeVar'
import styled from '@emotion/styled'
import UserInfoBar from '../user-info'
import { Text } from '../text'

export interface FeedbackModalProps {
  open: boolean
  onClose: () => void
  content: string
  materialName: string
  url: string
  userName: string
  userImgUrl: string
  createdAt: string
  rating: number
  t: any
}

export const FeedbackModal = ({
  open,
  onClose,
  content,
  materialName,
  url,
  userName,
  userImgUrl,
  createdAt,
  rating,
  t,
}: FeedbackModalProps) => {
  const containerStyles: React.CSSProperties = {
    display: 'flex',
    maxHeight: '475px',
    flexDirection: 'column',
    gap: '8px',
    overflowY: 'scroll',
  }
  return (
    <WuModal open={open} padding='20px 16px 16px 16px' onClose={onClose} maxWidth='500px'>
      <div style={containerStyles}>
        <UserInfoBar
          userName={userName}
          imgUrl={userImgUrl}
          createdAt={createdAt}
          rating={rating}
          t={t}
        />
        <Text variant={'body_m_500'} className='whitespace-pre-wrap'>
          {content}
        </Text>
        <Link href={url}>
          <Text variant={'label_s_400'} color='grayscale-400'>
            {materialName}
          </Text>
        </Link>
      </div>
    </WuModal>
  )
}

const Link = styled.a`
  text-decoration: none;
  color: ${themeVar.color.palette['grayscale-400']};
  text-underline-offset: 2px;
  &:hover {
    text-decoration: underline;
  }
`
