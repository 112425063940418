import React from 'react'

import { Icon, IconMapType } from '../../icon'
import type { MaterialTocSectionType, MaterialTocUnitType, OnPreviewUnitClickType } from '../types'

import { Text } from '../../text'

import styled from '@emotion/styled'
import themeVar from '../../../theme/themeVar'

type ExtendedMaterialTocUnitType = MaterialTocUnitType & {
  isTrialAllowed: boolean
  isAppOnlyTrialAllowed: boolean
}

interface TocUnitProps {
  onPreviewUnitClick?: OnPreviewUnitClickType
  t: any
  units: ExtendedMaterialTocUnitType[]
  section: MaterialTocSectionType & { isTrialAllowed: boolean }
  materialId: string
  subjectLanguage?: string
}

const TocUnit = ({
  onPreviewUnitClick,
  materialId,
  units,
  section,
  t,
  subjectLanguage,
}: TocUnitProps) => {
  return (
    <UnitContainer>
      {units.map((processedUnit, unitIdx) => {
        const { isTrialAllowed, isAppOnlyTrialAllowed, ...unit } = processedUnit
        const { name: unitName, composableType, previewable } = unit

        return (
          <UnitRowContainer
            key={`${unitName}-${unitIdx}`}
            clickable={previewable}
            onClick={() => {
              if (!onPreviewUnitClick) return
              onPreviewUnitClick({ unit, section, materialId, subjectLanguage })
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Icon
                  style={{
                    color: themeVar.color.palette['grayscale-800'],
                    marginTop: '4px',
                  }}
                  size={22}
                  type={composableType as IconMapType}
                />

                <Text variant='body_m_400' style={{ textAlign: 'start' }}>
                  {unitName}
                </Text>
              </div>

              {(isAppOnlyTrialAllowed || isTrialAllowed) && (
                <Text
                  variant='label_s_500'
                  color='primary-500'
                  style={{
                    whiteSpace: 'nowrap',
                    wordBreak: 'keep-all',
                    opacity: previewable ? 1 : 0,
                    transition: 'opacity 0.15s ease-in-out',
                    textDecoration: 'underline',
                    textDecorationColor: themeVar.color.palette['primary-500'],
                    textUnderlineOffset: '2px',
                    marginLeft: '30px',
                  }}
                >
                  {t(`crowdin:common.trial-immediately${isAppOnlyTrialAllowed ? '-app' : ''}`)}
                </Text>
              )}
            </div>
          </UnitRowContainer>
        )
      })}
    </UnitContainer>
  )
}

export default TocUnit

export const UnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeVar.distance.sm};
  font-size: ${themeVar.fontSize.sm};
`

export const UnitRowContainer = styled.button<{
  clickable: boolean | undefined
}>`
  display: flex;
  align-items: center;
  line-height: 1.5;
  border: none;
  padding: 0;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  background-color: transparent;
`
