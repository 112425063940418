import { LOCALE_TW } from '@/../next-i18next.config'
import { SHOP_PATH } from '@libs-components/utils/routes'

export interface ExamPagePropsTypes {
  materialId: string
  fullTestId: string
  preferredAppPlatform?: 'ios' | 'android'
}

export const withFullShopsUrl = (path: string, langInRoute: string = LOCALE_TW) =>
  `${process.env.NEXT_PUBLIC_SELF_DOMAIN}/${langInRoute}${path || ''}`

export const Web2Url = (path: string) =>
  `${process.env.NEXT_PUBLIC_FLASHCARD_WEB_DOMAIN}${path || ''}`

export const Web2Paths = {
  DECKS: '',
  MATERIALS: '',
  SIGN_IN: '/auth/sign-in',
  SIGN_UP: '/auth/sign-up',
  FORGET_PASSWORD: '/auth/forget-password',
  RESET_PASSWORD: '/auth/reset-password',
  SETTING_ACCOUNT: '/account/settings',
  SETTING_PREFERENCE: '/account/preference',
  SETTING_SUBSCRIPTION: '/account/subscription-order-history',
  ADMIN_INDEX: '/dashboard/classes',
}

/**
 * @deprecate Temporary re-export of SHOP_PATH from libs-components for capability
 * @description Importing/Adding SHOP_PATH directly in libs-components in the future
 **/
export const shopPaths = SHOP_PATH

export function paramsToObject(entries: [string, string][]) {
  const result: {
    [key: string]: string
  } = {}
  for (const [key, value] of entries) {
    result[key] = value
  }
  return result
}

export const getExamPageUrl = ({
  materialId,
  fullTestId,
  preferredAppPlatform,
}: ExamPagePropsTypes) => {
  const preferredAppPlatformParam = preferredAppPlatform
    ? `&preferredAppPlatform=${preferredAppPlatform}`
    : ''

  return `${process.env.NEXT_PUBLIC_FLASHCARD_WEB_DOMAIN}/exam/${fullTestId}?materialId=${materialId}${preferredAppPlatformParam}`
}

export function webMaterialDetail({
  productId,
  appView = 'default',
}: {
  productId: string
  appView: string
}) {
  if (appView === 'default') {
    return `${process.env.NEXT_PUBLIC_FLASHCARD_WEB_DOMAIN}/materials/${productId}`
  } else {
    return `${process.env.NEXT_PUBLIC_FLASHCARD_OLD_WEB_DOMAIN}/app/materials/${productId}?appView=${appView}`
  }
}

export function detailPageRoute(materialId: string) {
  return `/product/${materialId}`
}

export function subscriptionDetailRoute(subscriptionId: string) {
  return `/subscriptions/${subscriptionId}`
}

export const iOSDownloadUrl =
  'https://apps.apple.com/tw/app/wordup-%E5%96%AE%E5%AD%97%E6%95%91%E6%98%9F/id1447838299'

export const androidDownloadUrl =
  'https://play.google.com/store/apps/details?id=com.wordup.androidapp'
