import React from 'react'

import { GRADIENT_TEXT_STYLE } from './constants'
import { Icon } from '../../../icon'

const Used = ({ t }: { t: any }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '1rem',
        fontSize: '12px',
        lineHeight: '21px',
        letterSpacing: '0.06px',
        padding: '5.5px 0',
        background:
          'linear-gradient(225deg, rgba(114, 175, 255, 0.20) 0%, rgba(200, 144, 255, 0.20) 100%)', // Gradient/From_top_right/Primary500_purple500
      }}
    >
      <Icon
        type='check_circle'
        style={{
          color: '#C890FF', // purple-500
          marginRight: 4,
        }}
      />

      <span style={GRADIENT_TEXT_STYLE}>{t('crowdin:common.used')}</span>
    </div>
  )
}

export default Used
